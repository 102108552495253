/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/alt-text */
import React, { useEffect, useCallback, useRef, useState, useContext } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { Checkbox, Modal, Spin, Button, Alert } from 'antd'
import { LoadingOutlined, SwapOutlined } from '@ant-design/icons'
import { DEFAULT_MARKET_ADDRESS, EXTRA_MARKETS_SOURCE_TYPES, USER_FEE_ALLOW_REF_LINK } from '../../application'
import logo from '../../assets/dexlab_symbol.svg'
import BalancesTable from '../../componentsv2/UserInfoTable/BalancesTable'
import OpenOrderTable from '../../componentsv2/UserInfoTable/OpenOrderTable'
import FillsTable from '../../componentsv2/UserInfoTable/FillsTable'
import {
  useMarket,
  useMarkPrice,
  useOpenOrders,
  useBalances,
  MarketProvider,
  getTradePageUrl,
  useTradeHistory,
} from '../../utils/dex-markets'
import { useWallet } from '../../utils/wallet'
import { getDecimalCount } from '../../utils/utils'
import { numberWithCommas, calCurrencyPrice } from '../../utils/dexlab-utils'
import { ThemeContext, isDark } from '../../utils/Contexts/ThemeContext'
import { useReferrer } from '../../utils/referrer'
import queryString from 'query-string'
import { notify } from '../../utils/notifications'

import { Tab, Nav } from 'react-bootstrap'
import 'react-rangeslider/lib/index.css'

import TradeHeader from '../../componentsv2/layout/trade-header'
import Footer from '../../componentsv2/layout/footer'

import HeaderCoinSummary from '../../componentsv2/HeaderCoinSummary'
import TradeForm from '../../componentsv2/TradeForm'
import Orderbook from '../../componentsv2/Orderbook'
import TradesTable from '../../componentsv2/TradesTable'

import TradingViewWidget, { Themes as ChartThemes } from '../../componentsv2/layout/TradingViewWidget'
import { DexLabMarketV2Info, SolanaNetworkStatus } from '../../utils/types'

import GoogleAdvertise from '../../componentsv2/GoogleAdvertise'

import SimpleMarketsTable from '../../componentsv2/SimpleMarketsTable'
import { useTranslation } from 'react-i18next'
import StandaloneBalancesDisplay from '../../componentsv2/StandaloneBalancesDisplay'
import NoneSupportBrowser from '../../componentsv2/NoneSupportBrowser'
import ProjectInfoSummary from '../../componentsv2/ProjectInfoSummary'
import TransactionHistoryTable from '../../componentsv2/UserInfoTable/TransactionHistoryTable'

import OrderbookAllIcon from '../../images/svg/orderbook-all.svg'
import OrderbookBuyIcon from '../../images/svg/orderbook-buy-icon.svg'
import OrderbookSellIcon from '../../images/svg/orderbook-sell-icon.svg'
import { MARKETS_TO_STRING } from '../../utils/data/markets'
import SolanaStatusApi from '../../utils/client/solanaStatusApiConnector'

const antIcon = <LoadingOutlined style={{ color: '#FFFFFF', fontSize: 30 }} spin />

const TERMS_OF_USE_KEY = 'location_terms_of_use_v2'
const EXTRA_MARKET_TERMS_OF_USE_KEY = 'extra_market_terms_of_use_v2'

const MainCoinHeader = styled.div`
  margin-top: 90px;

  //@media only screen and (max-width: 768px) {
  //  margin-top: 165px;
  //}
`

const ALL_ORDERBOOK_DEPTH = 7
const NOT_ALL_ORDERBOOK_DEPTHY = 50

const MARKET_DATAS = JSON.parse(MARKETS_TO_STRING)

export default function TradeV2Page() {
  const history = useHistory()
  const { refCode, setRefCode, allowRefLink } = useReferrer()
  const { search } = useLocation()
  const parsed = queryString.parse(search)

  useEffect(() => {
    if (USER_FEE_ALLOW_REF_LINK && !!parsed.refCode && parsed.refCode !== refCode && allowRefLink) {
      notify({ message: `New referrer ${parsed.refCode} added` })
      setRefCode(parsed.refCode)
    }
  }, [parsed, refCode, setRefCode, allowRefLink])

  const [selectedMarket, setSelectedMarket] = useState<DexLabMarketV2Info | undefined>(
    MARKET_DATAS.find((f) => f.address === DEFAULT_MARKET_ADDRESS) || MARKET_DATAS[0],
  )
  const [termsOfUseConfirmCheckbox, setTermsOfUseConfirmCheckbox] = useState(false)
  const [extraMarketConfirmCheckbox, setExtraMarketConfirmCheckbox] = useState(false)
  const [isLocationTermsOfUse, setIsLocationTermsOfUse] = useState(localStorage.getItem(TERMS_OF_USE_KEY) ?? 'N')
  const [isExtraMarketTermsOfUse, setIsExtraMarketTermsOfUse] = useState(
    localStorage.getItem(EXTRA_MARKET_TERMS_OF_USE_KEY) ?? 'N',
  )
  const [visibleTermsOfUse, setVisibleTermsOfUse] = useState(false)
  const [visibleExtraMarketTermsOfUse, setVisibleExtraMarketTermsOfUse] = useState(false)
  const { marketAddress } = useParams()
  const currency = {
    id: 1,
    name: '미국 (USD/USDT)',
    standardType: 'USD',
    type: 'USD',
    provider: '-',
    lowPrice: 0,
    highPrice: 0,
    createdAt: '2020-12-25 00:00:00',
  }

  function onTermsOfUseConfirm() {
    if (termsOfUseConfirmCheckbox) {
      setIsLocationTermsOfUse('Y')
      setVisibleTermsOfUse(false)
    } else {
      window.alert('After consent, you can trade.')
    }
  }

  function onExtraMarketConfirm() {
    if (extraMarketConfirmCheckbox) {
      setIsExtraMarketTermsOfUse('Y')
      setVisibleExtraMarketTermsOfUse(false)
    } else {
      window.alert('After consent, you can trade.')
    }
  }

  function onTermsOfUseConfirmCheckbox(e) {
    setTermsOfUseConfirmCheckbox(e.target.checked)
  }

  function onExtraMarketConfirmCheckbox(e) {
    setExtraMarketConfirmCheckbox(e.target.checked)
  }

  useEffect(() => {
    if (isLocationTermsOfUse && isLocationTermsOfUse === 'N') {
      setVisibleTermsOfUse(true)
    } else {
      localStorage.setItem(TERMS_OF_USE_KEY, 'Y')
      setVisibleTermsOfUse(false)
    }
  }, [isLocationTermsOfUse])

  useEffect(() => {
    if (
      isExtraMarketTermsOfUse &&
      isExtraMarketTermsOfUse === 'N' &&
      selectedMarket &&
      EXTRA_MARKETS_SOURCE_TYPES.includes(selectedMarket.source)
    ) {
      setVisibleExtraMarketTermsOfUse(true)
    } else {
      localStorage.setItem(EXTRA_MARKET_TERMS_OF_USE_KEY, 'Y')
      setVisibleExtraMarketTermsOfUse(false)
    }
  }, [isExtraMarketTermsOfUse, selectedMarket])

  useEffect(() => {
    if (!_.isEmpty(MARKET_DATAS) && marketAddress) {
      let newAddress = marketAddress

      if (marketAddress === 'DYfigimKWc5VhavR4moPBibx9sMcWYVSjVdWvPztBPTa') {
        // DXL
        newAddress = 'HFZtxFAKcrEwjgUTrmCHhHWbVtDAWMV491QLQkXADBRq'
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z`
      } else if (marketAddress === 'DFdcGFcAVWZ3UgVgpbBChFKen3URZdZ8dmju8GTXQgCE') {
        // RLB
        newAddress = '72h8rWaWwfPUL36PAFqyQZU8RT1V3FKG7Nc45aK89xTs'
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a`
      } else if (marketAddress === '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT') {
        // SOL
        newAddress = '8BnEgHoWFysVcuFFX7QztDmzuH8r5ZFvyP3sYwn1XTh6'
        window.location.href = `https://www.dexlab.space/swap?pay=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&receive=So11111111111111111111111111111111111111112`
      } else {
        localStorage.setItem('marketAddress', JSON.stringify(newAddress))
      }
      setSelectedMarket(MARKET_DATAS.find((f) => f.address === newAddress))
    }
  }, [marketAddress])

  function setMarketAddress(address) {
    history.push(getTradePageUrl(address))
  }

  return selectedMarket && !_.isEmpty(MARKET_DATAS) ? (
    <MarketProvider allV2Markets={MARKET_DATAS} marketAddress={marketAddress} setMarketAddress={setMarketAddress}>
      <NoneSupportBrowser />
      <TradePageV2Inner
        allV2Markets={MARKET_DATAS}
        selectedMarket={selectedMarket}
        lpPool={undefined}
        currency={currency}
        setMarketAddress={setMarketAddress}
        isCurrencyLoading={false}
        queryStringParsed={parsed}
      />
      <Modal
        title="Terms of use"
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={visibleTermsOfUse}
        onOk={onTermsOfUseConfirm}
        okText="OK"
      >
        <span>
          The Dexlab DEX is a fully decentralised exchange. No representation or warranty is made concerning any aspect
          of the Dexlab DEX, including its suitability, quality, availability, accessibility, accuracy or safety. Access
          to and use of the Dexlab DEX is entirely at users’ own risk and could lead to substantial losses. Users take
          full responsibility for their use of the Dexlab DEX, including participation in pools and the sale or purchase
          of any products, including, without limitation, tokens and coins.
          <br />
          <br />
          The Dexlab DEX is not available to residents of Belarus, the Central African Republic, the Democratic Republic
          of Congo, the Democratic People’s Republic of Korea, the Crimea region of Ukraine, Cuba, Iran, Libya, Somalia,
          Sudan, South Sudan, Syria, the USA, Yemen, and Zimbabwe or any other jurisdiction in which accessing or using
          the Dexlab DEX is prohibited (“Prohibited Jurisdiction"). In using the exchange, you confirm that you do not
          reside in a Prohibited Jurisdiction.
        </span>
        <br />
        <br />
        <span style={{ fontWeight: 'bold' }}>
          DXL tokens are not available to residents of Prohibited Jurisdictions.
        </span>
        <br />
        <br />
        <span>
          Cookies are used to enhance the services available to you.
          <br />
          You agree to the use of cookies.
        </span>
        <br />
        <br />
        <Checkbox onChange={onTermsOfUseConfirmCheckbox}>I have read, understand and accept the above</Checkbox>
      </Modal>
      <Modal
        title="Extra Makret Trade Alert"
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={visibleExtraMarketTermsOfUse}
        onOk={onExtraMarketConfirm}
        okText="OK"
      >
        <span>
          Extra Market is a non-certified market that anyone can create and trade.
          <br />
          To prevent fraud, please make a transaction after carefully checking token information and project information
          through Explorer.
          <br />
          <br />
          All trades in the Extra Market are the responsibility of the trader.
          <br />
          Dexlab is not responsible for trading on the Extra Market.
          <br />
        </span>
        <br />
        <br />
        <Checkbox onChange={onExtraMarketConfirmCheckbox}>I have identified the risks to the trade.</Checkbox>
      </Modal>
    </MarketProvider>
  ) : (
    <LoadingView />
  )
}

function TradePageV2Inner({
  allV2Markets,
  lpPool,
  selectedMarket,
  currency,
  setMarketAddress,
  isCurrencyLoading,
  queryStringParsed,
}) {
  const [theme] = useContext(ThemeContext)
  const { t: trText } = useTranslation()
  const { market, marketName, baseCurrency, quoteCurrency } = useMarket()
  const { wallet, connected } = useWallet()
  const [selectTradeType, setSelectTradeType] = useState<'buy' | 'sell'>('buy')
  const [selectTabKey, setSelectTabKey] = useState('buy')
  const [selectChartType, setSelectChartType] = useState('SIMPLE') // SIMPLE or TV_CHART or DEXLAB
  const [viewProjectInfo, setViewProjectInfo] = useState(false)
  const [viewLpPoolInfo, setViewLpPoolInfo] = useState(false)
  const [orderBookType, setOrderBookType] = useState('ALL')
  const [orderBookDepth, setOrderBookDepth] = useState(20)
  const [transactionHistorys, setTransactionHistorys] = useState<any[]>(
    JSON.parse(localStorage.getItem('tx_history') ?? '[]'),
  )
  const [solanaStatus, setSolanaStatus] = useState<SolanaNetworkStatus | null>(null)

  const [tradeHistory, tradeHistoryLoaded] = useTradeHistory()
  const { markPrice, orderbook } = useMarkPrice(orderBookDepth)

  useEffect(() => {
    if (!viewProjectInfo && queryStringParsed.projectView === 'Y') {
      setViewProjectInfo(true)
    }
  }, [queryStringParsed])

  useEffect(() => {
    setSelectChartType(selectedMarket.chartType !== 'NONE' ? 'TV_CHART' : 'SIMPLE')
  }, [selectedMarket])

  useEffect(() => {
    const callApis = async () => {
      setSolanaStatus(await SolanaStatusApi.getCurrentSolanaStatus())
    }
    callApis().catch(() => console.log(`Error: Solana Performance API error`))
  }, [])

  const tickSize = currency.standardType === 'USD' ? market?.tickSize && getDecimalCount(market.tickSize) : 0
  const selectQuoteCurrency = currency.standardType === 'USD' ? quoteCurrency : currency.standardType

  let formattedMarkPrice =
    markPrice && numberWithCommas(calCurrencyPrice(markPrice, currency.lowPrice).toFixed(tickSize), currency)
  useEffect(() => {
    document.title = marketName
      ? `${formattedMarkPrice ?? '0.0'} | ${
          marketName.split('/')[0]
        }${selectQuoteCurrency} - The best DEX platform on SOLANA.`
      : 'Dexlab - The best DEX platform on SOLANA.'
  }, [formattedMarkPrice, marketName])

  const changeOrderRef = useRef<({ size, price }: { size?: number; price?: number }) => void>()

  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback((price) => changeOrderRef.current && changeOrderRef.current({ price }), []),
    onSize: useCallback((size) => changeOrderRef.current && changeOrderRef.current({ size }), []),
  }

  const onChangeSelectPrice = (newPrice) => {
    if (changeOrderRef.current) {
      changeOrderRef.current({ price: newPrice })
    }
  }

  const onChangeSelectSize = (newSize) => {
    if (changeOrderRef.current) {
      changeOrderRef.current({ size: newSize })
    }
  }

  const OpenOrdersTab = () => {
    const openOrders = useOpenOrders()
    return (
      <>
        <OpenOrderTable openOrders={openOrders} />
        {wallet && connected && (
          <div>
            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '18px' }}>
              * Transaction result takes 30s ~ 1m. (Transaction confirmation time)
            </span>
            <br />
          </div>
        )}
      </>
    )
  }

  const BalancesTab = () => {
    const balances = useBalances()
    return <BalancesTable balances={balances} />
  }

  const TransactionHistoryTab = () => {
    return (
      <>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleTxHistoryClearAll}>Clear All</Button>
        </div>{' '}
        <TransactionHistoryTable parentHistory={transactionHistorys} />
        <div>
          <span style={{ color: '#646464', fontSize: '12px' }}>
            * All transaction information that occurred recently in this browser (all connected wallets)
          </span>
          <br />
          <span style={{ color: '#646464', fontSize: '12px' }}>
            * It is saved in your browser and disappears if you clear the cache.
          </span>
          <br />
        </div>
      </>
    )
  }

  const handleTxHistoryClearAll = () => {
    const confirm = window.confirm(`Do you want to delete all history?`)
    if (!confirm) {
      return
    }
    localStorage.setItem('tx_history', JSON.stringify([]))
    setTransactionHistorys([])
  }

  const handleChangeOrderBookType = (type) => {
    setOrderBookType(type)
    if (type === 'ALL') {
      setOrderBookDepth(ALL_ORDERBOOK_DEPTH)
    } else {
      setOrderBookDepth(NOT_ALL_ORDERBOOK_DEPTHY)
    }
  }

  return (
    <>
      <TradeHeader />
      <div
        className="content-body"
        style={{ textAlign: 'center', backgroundColor: isDark(theme) ? '#000000' : '#e9ecf1' }}
        id="dashboard"
      >
        <div style={{ textAlign: 'left' }} className="container-fluid">
          <div className="row">
            <MainCoinHeader theme={theme} className="col-xl-12 col-lg-12 col-md-12 col-xxl-12" />
            <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12" style={{ marginBottom: '6px' }}>
              <Alert
                message={<b>Note</b>}
                description={
                  <>
                    This program is managed by Openbook and is currently no longer maintained. Full support will be
                    discontinued soon.(EOS December 31, 2024)
                    <br />
                    If necessary, please seek assistance through the{' '}
                    <a href="https://discord.com/invite/pX3n5Sercb" target="_blank">
                      Openbook community
                    </a>
                    .
                  </>
                }
                type="warning"
              />
            </div>
            <div className="col-xl-8 col-lg-12 col-md-12 col-xxl-8">
              <div className="card" style={{ marginBottom: 0 }}>
                {selectedMarket && baseCurrency && quoteCurrency && (
                  <HeaderCoinSummary
                    markPrice={markPrice}
                    selectedMarket={selectedMarket}
                    currency={currency}
                    viewLpPoolInfo={viewLpPoolInfo}
                    viewProjectInfo={viewProjectInfo}
                    setViewLpPoolInfo={setViewLpPoolInfo}
                    setViewProjectInfo={setViewProjectInfo}
                    market={market}
                    lpPool={lpPool}
                    baseCurrency={baseCurrency}
                    quoteCurrency={quoteCurrency}
                  />
                )}
              </div>
              {!viewProjectInfo && !viewLpPoolInfo && marketName && selectedMarket ? (
                <div className="tradingview-widget-container card" style={{ height: '520px', marginTop: '3px' }}>
                  <iframe
                    height="100%"
                    width="100%"
                    id="geckoterminal-embed"
                    title="GeckoTerminal Embed"
                    src={`https://birdeye.so/tv-widget/${selectedMarket.baseMint}?chain=solana&chartType=candle&chartInterval=60&chartLeftToolbar=hide`}
                    frameBorder="0"
                    allow="clipboard-write"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : null}
              {selectedMarket && viewProjectInfo && (
                <div className="tradingview-widget-container card" style={{ height: '520px', marginTop: '6px' }}>
                  <ProjectInfoSummary market={selectedMarket} />
                </div>
              )}
              <div className="row" style={{ marginTop: '6px' }}>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xxl-6" style={{ paddingRight: '1px' }}>
                  <Tab.Container defaultActiveKey="orderbook">
                    <div className="card" style={{ marginBottom: '2px' }}>
                      <div className="card-header">
                        <Nav variant="pills">
                          <Nav.Link className="card-title" style={{ fontWeight: 'bold' }} eventKey="orderbook">
                            {trText('orderbook')}
                          </Nav.Link>
                          <div style={{ marginLeft: '6px' }}>
                            <img
                              style={{
                                width: '20px',
                                fill: '#FFF',
                                color: '#FFF',
                                cursor: 'pointer',
                                borderStyle: orderBookType === 'ALL' ? 'solid' : 'none',
                                borderWidth: '0.5px',
                              }}
                              src={OrderbookAllIcon}
                              onClick={() => {
                                handleChangeOrderBookType('ALL')
                              }}
                            />
                            <img
                              style={{
                                width: '20px',
                                marginLeft: '3px',
                                marginRight: '3px',
                                cursor: 'pointer',
                                borderStyle: orderBookType === 'BID' ? 'solid' : 'none',
                                borderWidth: '0.5px',
                              }}
                              src={OrderbookBuyIcon}
                              onClick={() => {
                                handleChangeOrderBookType('BID')
                              }}
                            />
                            <img
                              style={{
                                width: '20px',
                                cursor: 'pointer',
                                borderStyle: orderBookType === 'ASK' ? 'solid' : 'none',
                                borderWidth: '0.5px',
                              }}
                              src={OrderbookSellIcon}
                              onClick={() => {
                                handleChangeOrderBookType('ASK')
                              }}
                            />
                          </div>
                        </Nav>
                      </div>
                      <div className="card-body market-limit" style={{ height: '460px' }}>
                        <Tab.Content>
                          <Tab.Pane eventKey="orderbook">
                            {orderBookType === 'ALL' && (
                              <Orderbook
                                smallScreen={false}
                                markPrice={markPrice}
                                orderbook={orderbook}
                                depth={ALL_ORDERBOOK_DEPTH}
                                type={orderBookType}
                                onPrice={onChangeSelectPrice}
                                onSize={onChangeSelectSize}
                              />
                            )}
                            {orderBookType !== 'ALL' && (
                              <Orderbook
                                smallScreen={false}
                                markPrice={markPrice}
                                orderbook={orderbook}
                                depth={NOT_ALL_ORDERBOOK_DEPTHY}
                                type={orderBookType}
                                onPrice={onChangeSelectPrice}
                                onSize={onChangeSelectSize}
                              />
                            )}
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-xxl-6" style={{ paddingLeft: '1px' }}>
                  <Tab.Container
                    defaultActiveKey="buy"
                    onSelect={(selectedKey) => {
                      if (selectedKey) {
                        setSelectTabKey(selectedKey)
                        if (selectedKey === 'buy' || selectedKey === 'sell') {
                          setSelectTradeType(selectedKey)
                        }
                      }
                    }}
                  >
                    <div className="card" style={{ marginBottom: '2px' }}>
                      <div className="card-header">
                        <Nav variant="pills">
                          <Nav.Link className="card-title" style={{ fontWeight: 'bold' }} eventKey="buy">
                            {trText('order_buy')}
                          </Nav.Link>
                          <Nav.Link className="card-title" style={{ fontWeight: 'bold' }} eventKey="sell">
                            {trText('order_sell')}
                          </Nav.Link>
                        </Nav>
                      </div>
                      <div className="card-body market-limit" style={{ height: '460px' }}>
                        <Tab.Content>
                          <Tab.Pane eventKey="buy"></Tab.Pane>
                          <Tab.Pane eventKey="sell"></Tab.Pane>
                        </Tab.Content>
                        {selectTabKey === 'buy' || selectTabKey === 'sell' ? (
                          <TradeForm tradeType={selectTradeType} setChangeOrderRef={componentProps.onChangeOrderRef} />
                        ) : null}
                      </div>
                    </div>
                  </Tab.Container>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-xxl-12">
                  <Tab.Container defaultActiveKey="open-orders">
                    <div className="card" style={{ marginBottom: '2px' }}>
                      <div className="card-header">
                        <Nav variant="pills">
                          <Nav.Link eventKey="open-orders">{trText('tab_open_order')}</Nav.Link>
                          <Nav.Link eventKey="fills">{trText('tab_recent_trade_history')}</Nav.Link>
                          <Nav.Link eventKey="balances">{trText('tab_balances')}</Nav.Link>
                          <Nav.Link eventKey="tx_history">Transaction History</Nav.Link>
                        </Nav>
                      </div>
                      <div className="card-body open-orders-table" style={{ minHeight: '407px' }}>
                        <div className="market-history market-order">
                          <GoogleAdvertise
                            client="ca-pub-2894164767928910"
                            slot="5637307275"
                            format="auto"
                            responsive="true"
                          />
                          <Tab.Content>
                            <Tab.Pane eventKey="open-orders">
                              <OpenOrdersTab />
                              {connected ? <StandaloneBalancesDisplay /> : null}
                            </Tab.Pane>
                            <Tab.Pane eventKey="fills">
                              <FillsTable />
                            </Tab.Pane>
                            <Tab.Pane eventKey="balances">
                              <BalancesTab />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tx_history">
                              <TransactionHistoryTab />
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: 0 }} className="col-xl-4 col-lg-12 col-md-12 col-xxl-4">
              <div className="row">
                <div className="col-12">
                  <div className="card" style={{ minWidth: '380px', marginBottom: '2px' }}>
                    {!isCurrencyLoading && currency && (
                      <SimpleMarketsTable
                        allV2Markets={allV2Markets}
                        markPrice={markPrice}
                        market={market}
                        marketName={marketName}
                        setMarketAddress={setMarketAddress}
                        currency={currency}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card" style={{ height: '700px', marginBottom: '2px' }}>
                    {!isCurrencyLoading && tradeHistoryLoaded && (
                      <TradesTable
                        smallScreen={false}
                        currency={currency}
                        tradeHistory={tradeHistory ?? []}
                        tradeHistoryLoaded={tradeHistoryLoaded}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

function LoadingView() {
  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <img style={{ width: '200px', height: '60px' }} src={logo} alt="Dexlab OpenBook DEX" />
      <div>The best DEX platform on SOLANA.</div>
      <div style={{ marginTop: '30px' }}>
        <Spin indicator={antIcon} />
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <div style={{ marginBottom: '20px' }}>
          <span>
            <a style={{ color: '#FFFFFF' }} href="https://www.dexlab.space" target="_blank" rel="noopener noreferrer">
              Dexlab
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
          <span>
            <a
              style={{ color: '#FFFFFF' }}
              href="https://stats.uptimerobot.com/8OZwzIjnn1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Service Status
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
          <span>
            <a
              style={{ color: '#FFFFFF' }}
              href="https://minting.dexlab.space"
              target="_blank"
              rel="noopener noreferrer"
            >
              Minting Lab
            </a>
          </span>{' '}
          <span style={{ color: '#646464' }}>|</span>{' '}
        </div>
      </div>
    </div>
  )
}
